<template>
  <div>
    <l-search @search="search(1)" @reset="resetForm">
      <l-form layout="inline" laba-width="70px">
        <l-form-item label="集团">
          <l-select v-model="searchFilter.companyId" placeholder="请选择集团">
            <l-select-option label="全部" :value="-2">全部</l-select-option>
            <l-select-option label="运营支撑系统" :value="-1">运营支撑系统</l-select-option>
            <l-select-option v-for="item in companyList" :key="item.id" :value="item.id">{{ item.name }}</l-select-option>
          </l-select>
        </l-form-item>
      </l-form>
    </l-search>

    <!-- 列表区域-STR -->
    <l-table-common
      :data-source="tableData"
      @change="search"
    >
      <l-table-column data-index="id" title="流水号" />
      <l-table-column data-index="account" title="登录账号" />
      <l-table-column data-index="userName" title="登录人" />
      <l-table-column data-index="ip" title="登录IP地址" />
      <l-table-column data-index="address" title="登录地址" />
      <l-table-column data-index="targetIp" title="目标IP地址" />
      <l-table-column data-index="status" title="状态">
        <template slot-scope="scope">
          <span v-if="scope.status !== 0" class="deep-green">成功</span>
          <span v-if="scope.status === 0" class="red">失败</span>
        </template>
      </l-table-column>
      <l-table-column
        data-index="reason"
        title="失败原因"
      >
        <template slot-scope="scope">
          <span v-if="scope.status !== 0" class="deep-green">--</span>
          <span v-if="scope.status === 0" class="red">{{ scope.reason }}</span>
        </template>
      </l-table-column>
      <l-table-column
        title="创建时间"
      >
        <template slot-scope="scope">
          {{ scope.createTime | date }}
        </template>
      </l-table-column>
    </l-table-common>
    <!-- 列表区域-END -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AuthAppList',
  data() {
    return {
      searchFilter: {
        companyId: -1
      },
      tableData: {
        isLoading: false,
        total: 0,
        page: 1,
        size: 10,
        list: []
      },
    }
  },
  computed: {
    ...mapState({
      companyList: state => state.auth.companyList
    })
  },
  mounted() {
    this.search(1)
    this.$store.dispatch('auth/authCompany')
  },
  methods: {
    async search(page) {
      page = (page && page.current) || page || this.tableData.page
      const param = {
        page: page || 1,
        size: this.tableData.size
      }
      for (const key in this.searchFilter) {
        param[key] = this.searchFilter[key]
      }
      this.tableData.list = []
      this.tableData.isLoading = true

      try {
        const data = await this.$store.dispatch('auth/authLoginLog')
        this.tableData.isLoading = false
        this.tableData.list = data.list || []
        this.tableData.total = data.count
        this.tableData.page = page || 1
      } catch (e) {
        this.tableData.isLoading = false
      }
    },
    resetForm() {
      this.searchFilter = this.$options.data().searchFilter
      this.search(1)
    }
  }
}
</script>

<style lang="scss">
.system-index-btns {
  text-align: right;
  & i {
    font-size: 12px;
    margin-right: 8px;
    vertical-align: top;
  }
  & span {
    display: inline-block;
    vertical-align: top;
  }
}
.device-info {
  .device-info-body {
    margin: 15px 15px 70px;
  }
  .l-dialog__header {
    border-bottom: 1px solid $l-border-base;
  }
  .info-line {
    margin-bottom: 10px;
    span {
      display: inline-block;
      padding: 5px 0;
      color: $l-text-primary;
      font-size: 14px;
      line-height: 24px;
    }
    span.name {
      min-width: 110px;
      line-height: 24px;
    }
  }
}
</style>
